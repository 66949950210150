<template>
  <b-row class="justify-content-center">
    <b-col
      lg="8"
      md="12"
    >
      <b-row>
        <b-col
          md="6"
          sm="12"
        >
          <b-card
            class="text-center card-btn"
            header=" "
            title="Search"
          >
            <b-card-text>
              <div class="d-block">
                <b-avatar
                  class="mb-1"
                  variant="light-primary"
                  size="45"
                  block
                >
                  <feather-icon
                    size="21"
                    icon="SearchIcon"
                  />
                </b-avatar>
              </div>
              Click search to start your corporate search journey. <br>
              Check the box to perform an assisted search.
            </b-card-text>
            <b-card-footer class="text-right p-0 pt-1">
              <b-form-checkbox
                v-model="assistedBooking"
                class="mr-1 mt-50"
                switch
                inline
              >
                Assisted Search
              </b-form-checkbox>
              <b-button
                variant="primary"
                @click="generateURL('search')"
              >
                Search
              </b-button>
            </b-card-footer>
          </b-card>
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <b-card
            class="text-center card-btn"
            header=" "
            title="Share"
          >
            <b-card-text>
              <div class="d-block">
                <b-avatar
                  class="mb-1"
                  variant="light-primary"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="SendIcon"
                  />
                </b-avatar>
              </div>
              Click share to send your desired result someone else. <br>
              Then they can complete the journey.
            </b-card-text>
            <b-card-footer class="text-right p-0 pt-1">
              <b-button
                variant="primary"
                @click="generateURL('share')"
              >
                Share
              </b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'SearchRedirect',
  props: {
    searchType: {
      type: String,
      default: 'search',
    },
  },
  data() {
    return {
      accountInfo: this.$store.state.auth,
      assistedBooking: false,
    }
  },
  methods: {
    generateURL(searchType) {
      const token = this.accountInfo.Token
      const expiry = this.accountInfo.Expiry
      window.open(`${process.env.VUE_APP_GZ_URL}/?id=${token}&expiry=${expiry}&journey=${searchType}${this.assistedBooking ? '&type=assisted' : ''}`)
    },
  },
}
</script>
